import { Environment } from '@abp/ng.core';

const baseUrl = window.location.origin;

export const environment = {
  production: true,
  hmr: false,
  application: {
    baseUrl,
    name: 'Knovvu Portal',
  },
  oAuthConfig: {
    issuer: '/ldm-identity',
    skipIssuerCheck: true,
    strictDiscoveryDocumentValidation: false,
    showDebugInformation: true,
    redirectUri: baseUrl,
    clientId: 'Ldm_App',
    responseType: 'code',    
    scope: 'offline_access Ldm',
    requireHttps: false,
  },
  apis: {
    default: {
      url: '',
      rootNamespace: 'Sestek.Ldm',
    },
  },
} as Environment;
