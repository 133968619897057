import { CoreModule } from '@abp/ng.core';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { ExtensibleModule } from '@abp/ng.components/extensible'
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import {
  CommercialUiConfigModule,
  OPEN_MY_LINK_USERS_MODAL,
} from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
import { HttpErrorComponent, ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AbpOAuthModule } from "@abp/ng.oauth";
import { APP_ROUTE_PROVIDER } from './route.provider';
import { IdentityModule } from '@volo/abp.ng.identity';
import { PermissionManagementModule } from '@abp/ng.permission-management';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { TreeModule } from '@abp/ng.components/tree';
import { PageModule } from '@abp/ng.components/page';
import { LdmOrganizationUnitsComponent } from './ldm-organization-units/ldm-organization-units.component';
import { LdmOrganizationMembersComponent } from './ldm-organization-units/ldm-organization-members/ldm-organization-members.component';
import { LdmOrganizationMembersModalBodyComponent } from './ldm-organization-units/ldm-organization-members/ldm-organization-members-modal-body.component';
import { LdmAbstractOrganizationUnitComponent } from './ldm-organization-units/ldm-abstract-organization-unit/ldm-abstract-organization-unit.component';
import { LdmUsersComponent } from './ldm-users/ldm-users.component';
import { NgbDropdownModule, NgbNavModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ACCOUNTS_ACCOUNT_ROUTE_PROVIDER } from './accounts/account/providers/account-route.provider';
import { SharedModule } from './shared/shared.module';
import { PRODUCTS_PRODUCT_ROUTE_PROVIDER } from './products/product/providers/product-route.provider';
import { SUBSCRIPTIONS_SUBSCRIPTION_ROUTE_PROVIDER } from './subscriptions/subscription/providers/subscription-route.provider';
import { SUBSCRIPTION_PRODUCTS_SUBSCRIPTION_PRODUCT_ROUTE_PROVIDER } from './subscription-products/subscription-product/providers/subscription-product-route.provider';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorResponseInterceptor } from './shared/interceptors/http-error-response.interceptor';
import { NgSelectModule } from '@ng-select/ng-select';
import { PRODUCT_OPTIONS_PRODUCT_OPTION_ROUTE_PROVIDER } from './product-options/product-option/providers/product-option-route.provider';
import { SUBSCRIPTION_PRODUCT_OPTIONS_SUBSCRIPTION_PRODUCT_OPTION_ROUTE_PROVIDER } from './subscription-product-options/subscription-product-option/providers/subscription-product-option-route.provider';
import { VALIDATION_MESSAGE_PROVIDER } from './shared/services/validation-message.service';
import { LICENSE_USAGE_LOGS_LICENSE_USAGE_LOG_ROUTE_PROVIDER } from './license-usage-logs/license-usage-log/providers/license-usage-log-route.provider';
import { LICENSE_USAGE_REPORTS_LICENSE_USAGE_REPORT_ROUTE_PROVIDER } from './license-usage-reports/license-usage-report/providers/license-usage-report-route.provider';
import { NOTIFICATION_ROUTE_PROVIDER } from './notifications/notification/providers/notification-route.provider';
import { MatRadioModule } from '@angular/material/radio';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { LdmTitleStrategy } from './ldm-title-strategy';
import { TitleStrategy } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    LdmUsersComponent,
    LdmOrganizationUnitsComponent,
    LdmOrganizationMembersComponent,
    LdmOrganizationMembersModalBodyComponent,
    LdmAbstractOrganizationUnitComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbNavModule,
    ReactiveFormsModule,
    FormsModule,
    NgxValidateCoreModule,
    PermissionManagementModule,
    TreeModule,
    PageModule,
    NgSelectModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    SharedModule,
    MatRadioModule,
    ExtensibleModule,
    IdentityModule,
    //PremiumReportingConfigModule.forRoot(),
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: HttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      },
    }),
    AbpOAuthModule.forRoot(),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonModule.forRoot(),
    CommercialUiConfigModule.forRoot(),
    NgxsModule.forRoot([]),
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    NgbNavModule,
    ACCOUNTS_ACCOUNT_ROUTE_PROVIDER,
    PRODUCTS_PRODUCT_ROUTE_PROVIDER,
    SUBSCRIPTIONS_SUBSCRIPTION_ROUTE_PROVIDER,
    SUBSCRIPTION_PRODUCTS_SUBSCRIPTION_PRODUCT_ROUTE_PROVIDER,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorResponseInterceptor,
      multi: true,
    },
    {
      provide: OPEN_MY_LINK_USERS_MODAL,
      useValue: false,
    },
    PRODUCT_OPTIONS_PRODUCT_OPTION_ROUTE_PROVIDER,
    SUBSCRIPTION_PRODUCT_OPTIONS_SUBSCRIPTION_PRODUCT_OPTION_ROUTE_PROVIDER,
    VALIDATION_MESSAGE_PROVIDER,
    LICENSE_USAGE_LOGS_LICENSE_USAGE_LOG_ROUTE_PROVIDER,
    LICENSE_USAGE_REPORTS_LICENSE_USAGE_REPORT_ROUTE_PROVIDER,
    NOTIFICATION_ROUTE_PROVIDER,
    { provide: TitleStrategy, useClass: LdmTitleStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
